import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import Page from '../components/Page'
import SignUp from '../components/FS/SignUp'
import Features from '../components/features'
import withContentfulBlocks from '../components/withContentfulBlocks'

class FeaturesIndex extends React.Component {
  render () {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <ContentfulPage
        blocks={this.props.blocks}
        footerColor='white'
      >
        <Helmet title={`${siteTitle} Features`} />

        <Page.Panel color='white'>
          <Page.Panel.Label color='accent'>
            Field Supply features
          </Page.Panel.Label>

          <Features />
        </Page.Panel>
        <SignUp />
      </ContentfulPage>
    )
  }
}

export default withContentfulBlocks(FeaturesIndex)

export const pageQuery = graphql`
  query FSFeaturesQuery {
    site {
      siteMetadata {
        siteName
      }
    }
    page: contentfulPage(contentful_id: { eq: "4cb8Q2aBj44D97eyHnDxFk" }) {
      ...PageBlocksFragment
    }
  }
`
