import React from 'react'

import styles from './features.module.scss'
import Checkmark from '../icons/Checkmark'

const Features = () => {
  return (
    <table className={styles.features}>
      <thead>
        <tr>
          <th colSpan='3'>Reporting</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Sales</th>
          <td>Custom</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <th rowSpan='5'>Commodity Management</th>
          <td>Adjustment</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Batched</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Real-time POS</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Batch/expiry tracking</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>VVM status</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colSpan='3'>Master data</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Service delivery</th>
          <td>Facilities</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <th>Commodity Management</th>
          <td>Products, warehouses, business rules</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <th>Authorization</th>
          <td>Multi-program, funder and service-level visibility</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colSpan='3'>Planning</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th rowSpan='3'>Forecasting</th>
          <td>Target population</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Consumption-based</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Custom algorithm</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <th rowSpan='2'>Supply and demand planning</th>
          <td>Fixed supply period</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>S&OP</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <th rowSpan='3'>Distribution planning</th>
          <td>Order generator</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Campaigns</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Routine push</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <th>Route planning</th>
          <td colSpan='2' style={{ textAlign: 'right' }}>Coming soon</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colSpan='3'>Shipments</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th rowSpan='2'>Tracking</th>
          <td>Outbound / inbound</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>Real-time</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <th rowSpan='2'>Digital POD</th>
          <td>Digital signature</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
        <tr>
          <td>GPS / Geofence</td>
          <td><span className={styles.features__checkmark}><Checkmark /></span></td>
        </tr>
      </tbody>
    </table>
  )
}

export default Features
